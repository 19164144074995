import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Layout } from '../components/layout'
import { ModularContent } from '../components/content'

const IndexPage = () => {
  const homepageData = useStaticQuery(graphql`
    {
      sanityHomepage {
        seo {
          title
          description
          image {
            asset {
              url
            }
          }
        }
        modularContent {
            ...moduleContent
                # modules {
                #     ... on SanityHero {
                #         _key
                #         _type
                #         headline
                #         image {
                #             asset {
                #                 gatsbyImageData(
                #                     layout: FULL_WIDTH,
                #                     fit: FILL
                #                 )
                #                 altText
                #                 url
                #             }
                #         }
                #         subheading
                #         link {
                #             blank
                #             text
                #             type
                #             externalLink
                #             relativeInternalLink
                #             internalLink {
                #                 ... on SanityBook {
                #                     slug {
                #                         current
                #                     }
                #                     internal {
                #                         type
                #                     }
                #                 }
                #                 ... on SanityEvent {
                #                     slug {
                #                         current
                #                     }
                #                     internal {
                #                         type
                #                     }
                #                 }
                #                 ... on SanityMusic {
                #                     slug {
                #                         current
                #                     }
                #                     internal {
                #                         type
                #                     }
                #                 }
                #                 ... on SanityPage {
                #                     slug {
                #                         current
                #                     }
                #                     internal {
                #                         type
                #                     }
                #                 }
                #                 ... on SanityPost {
                #                     slug {
                #                         current
                #                     }
                #                     internal {
                #                         type
                #                     }
                #                 }
                #             }
                #         }
                #     }
                #     ... on SanityFeaturedLogos {
                #         _key
                #         _type
                #         title
                #         logos {
                #             _key
                #             name
                #             image {
                #                 asset {
                #                     gatsbyImageData
                #                 }
                #             }
                #         }
                #     }
                #     ... on SanityForm {
                #         _key
                #         _type
                #         method
                #         name
                #         netlify
                #         submit
                #         fields {
                #             label
                #             name
                #             placeholder
                #             type
                #             _key
                #         }
                #     }
                #     ... on SanityMainImage {
                #         _key
                #         _type
                #         alt
                #         caption
                #         asset {
                #             gatsbyImageData
                #         }
                #     }
                #     ... on SanityRichText {
                #         _key
                #         _type
                #         _rawContent(resolveReferences: { maxDepth: 5})
                #     }
                #     ... on SanitySplitContent {
                #         _key
                #         _type
                #         alignment
                #         description
                #         headline
                #         image {
                #             alt
                #             asset {
                #                 gatsbyImageData(
                #                     fit: FILL
                #                     layout: FULL_WIDTH
                #                     placeholder: BLURRED
                #                     formats: [ AUTO, WEBP, JPG ]
                #                 )
                #                 altText
                #             }
                #         }
                #     }
                #     ... on SanityBlockquote {
                #         _key
                #         _type
                #         alignment
                #         attribution
                #         externalLink
                #         quoteContent
                #     }
                # }
            }
      }
    }
  `)

  return (
    <Layout seo={homepageData.sanityHomepage.seo}>
      {homepageData.sanityHomepage.modularContent?.modules &&
        <ModularContent modules={homepageData.sanityHomepage.modularContent?.modules} />
      }
    </Layout>
  )
}

export default IndexPage
